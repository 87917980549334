
html {
	font-size:1rem;
	@include media-breakpoint-down(sm) { 
		font-size:0.85rem;
	}
}
body {
  font-family: 'Raleway', sans-serif;
  color: $dark;
}
.heading {
  font-family: 'Fredoka One', cursive;
  
}
.main{
	@include media-breakpoint-down(lg) {
		//border:4px solid blue;
		//min-width: auto;
	}
	@include media-breakpoint-down(md) {
		//border:4px solid green;
		min-width: 992px;
	}  
	@include media-breakpoint-down(sm) {
		//border:4px solid yellow;
		min-width: auto;
	}  
}
.branding{
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none !important;
	img{
		height:90px;
		margin-right: 10px;
	}
	h1{
		font-size: 4.5rem;
		line-height: 1;
		margin-bottom: 0;
	}
	@include media-breakpoint-down(sm) { 
		img{
			height:65px;
		}
		h1{
			font-size: 3.8rem;
		}
	}
	&.branding-small{
		img{
			height:60px;
		}
		h1{
			font-size: 2.5rem;
		}
		@include media-breakpoint-down(sm) { 
			img{
				height:45px;
			}
			h1{
				font-size: 2.3rem;
			}
		}		
	}
}
ul.ticks{
	list-style: none;
	li{
	    position: relative;
	    font-size: 1rem;
	    color: $white;
	    padding: 3px 0;
	    &:before{
		   font-family: "Font Awesome 5 Free";
		   content: "\f058";
		   position: absolute;
		   left: -20px;
		   top: 4px;
		   color: $primary;
	 	   
	    }
    }
}
.form-group.select-group{
	padding: 0;
	border-radius: 7px;
	border: 2px solid transparent;
	color: $dark;
	position: relative;
	&.half{
		border-radius: 7px 0 0 7px;
		.select-inner{
			border-radius: 0 5px 5px 0;
		}
	}
	.select-inner{
		padding: 0;
		border: 1px solid rgba($dark, 0.5);
		border-radius: 5px;
		margin-top: 20px;
		select{
			border: none;
			color: $dark;
			box-shadow: none;
			height: 38px;
			border-radius: 0 5px 5px 0;
			&:focus{
				box-shadow: none;
			}
		}
	}
	.field-error{
		top: 100%;
		left: 50%;
		width: 100%;
		visibility: hidden;
		transform: translateX(-50%);
		border-color: transparent;
		box-shadow: 2px 2px 6px rgba($primary, 0.3);
		.arrow{
			left: 50%;
			transform: translateX(-50%);
			&:before{
				border-color: transparent;
			}
			&:after{
				border-bottom-color: $primary;
			}
		}
		.popover-body{
			color: $white;
		}
		
	}
}
.radio-group {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  
  .radio-option{
	  width: 20%;
	  text-align: center;
	  label{
		  text-align: center;
	  }
  }
  input[type="radio"] {
    display: none;
    &:checked {
      + .radio-box {
        background-color: $primary;
        color: $white;
       
        
      }
      + span {
          color: $primary;
          transform: translateY(70px);
          
        }
    }
  }
  .radio-box {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: 3px solid rgba($dark, 0.6);
    border-radius: 5px;
    transition: all 250ms ease;
    will-change: transition;
    margin: 0 auto;
    color: rgba($dark, 0.6);
    text-align: center;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:active{
      transform: translateY(10px);
    }
    
  }
  span {
            transition: all 300ms ease;
      font-size: .825rem;
      user-select: none;
      color: $dark;
      
    }
}
.hide-select{
	
	max-height: 0;
	overflow: hidden; 
	transition: all .25s ease-in-out;
	will-change: transition;
	.select-inner{
		opacity: 0;
		transition: all .5s ease-in-out .25s;
		transform: translateY(-10px);
	}
	&.open{
		overflow: visible;
		max-height: 55px;
		.select-inner{
			opacity: 1;
			transform: translateY(0);
		}
	}
}  
.alert-danger {
    color: $primary;
    background-color: #f8d7da;
    border-color: rgba($primary, 0.5);
    margin-top: 20px;
    width: 100%;
}

.input-holder{
	.select-inner{
		margin-top:0;
	}
	label{
		margin-top:10px;
		text-align: left;
		display: block;
	}
}

.label-select{
	margin-top:10px;
}
.label-select ~ .select-inner{
	margin-top:-3px !important;
}
.anim-form{
.input-holder{
	position: relative;
	margin-top: 20px;
	padding: 0 !important;
	border-radius: 7px;
	border: 2px solid transparent;
	color: $dark;
	&.half{
		border-radius: 7px 0 0 7px;
		input{
			border-radius: 5px 0 0 5px;
		}
	}
	label{
		position: absolute;
		font-size: 16px;
		color: rgba($dark, 0.5);
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 3;
		transition: all .25s ease-in-out;
		padding: 0 3px;
		line-height: 1;
		pointer-events: none;
		margin-top:0;
		&.textarea-label{
			top: 12px;
		transform: translateY(0);
		}
	}
	.label-select{
		display:none;
	}
	input{
		width: 100%;
		border-radius: 5px;
		height: 40px;
		padding: 0 20px;
		font-size: 16px; 
		box-shadow: none;
		border: 1px solid rgba($dark, 0.5);
		color: $dark;
		&:focus{
			outline: none;
			box-shadow: none;
		}
	}
	textarea{
		width: 100%;
		border-radius: 5px;
		
		padding: 12px 20px;
		font-size: 16px; 
		box-shadow: none;
		border: 1px solid rgba($dark, 0.5);
		
		&:focus{
			outline: none;
		}
	}
	
	&.focused{
		border: 2px solid rgba($dark, 0.5);
		label{
			
			font-size: 13px;
			transform: translateY( 0);
			top: -3px;
			background-color: #fff;
		}
		input{
			border: 1px solid rgba($dark, 0.5);
		}
		textarea{
			border: 1px solid rgba($dark, 0.5);
		}
	}
	&.focuson{
		border: 2px solid $primary;
		label{
			color: $primary;
		}
		input{
			border: 1px solid $primary;
		}
		textarea{
			border: 1px solid $primary;
		}
	}
	
}
}
.mainnav{
	
	z-index: 9;
    
    ul{
	    li{
		    a{
			    color: #fff;
		    }
	    }
    }
}
.landing-page{
	.mainnav{
		position: absolute;
		z-index: 9;
	    right: 0;
    }
}
.overlay{
	position: fixed;
	z-index: 99;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba($dark, 0.7);
	display: flex;
	align-items: center;
	transform: translateY(-50px);
	pointer-events: none;
	opacity: 0;
	transition: all .5s ease-in-out;
	.overlay-closer{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
	.row > * {
		z-index: 99;
	}
	&.open{
		transform: translateY(0);
	pointer-events: all;
	opacity: 1;
	}
}

.gray-1{
	background: rgba( $dark, .2 );
}

.form-info{
	margin-top:-1em;
	margin-bottom: 1em;
	margin-left:0.5em;
	width: 100%;
	text-align:left;
}

a.btn-store{
	display:inline-block;
	img{
		height:50px;
	}
}
@include media-breakpoint-down(sm) { 
	.footer-social{
		margin-top:20px;
	}
}

//form owner
.right-auto{
	margin-right:auto;
}

.show-1,.show-2,.show-3,.show-4,.show-5,.show-6,.show-7,.show-8,.show-9,.show-10,.show-13{
	display:none;
	pointer-events: none;
}
.profile-type-1{
	.show-1{
		display:block !important;
		pointer-events: unset;
	}
}
.profile-type-2{
	.show-2{
		display:block !important;
		pointer-events: unset;
	}
}
.profile-type-3{
	.show-3{
		display:block !important;
		pointer-events: unset;
	}
}
.profile-type-4{
	.show-4{
		display:block !important;
		pointer-events: unset;
	}
}
.profile-type-5{
	.show-5{
		display:block !important;
		pointer-events: unset;
	}
}
.profile-type-6{
	.show-6{
		display:block !important;
		pointer-events: unset;
	}
}
.profile-type-7{
	.show-7{
		display:block !important;
		pointer-events: unset;
	}
}
.profile-type-8{
	.show-8{
		display:block !important;
		pointer-events: unset;
	}
}
.profile-type-9{
	.show-9{
		display:block !important;
		pointer-events: unset;
	}
}
.profile-type-10{
	.show-10{
		display:block !important;
		pointer-events: unset;
	}
}
.profile-type-13{
	.show-13{
		display:block !important;
		pointer-events: unset;
	}
}

@include media-breakpoint-down(md) { 
	.progress-box{
		width:100%;
		order:3;
		flex-basis:auto;
		flex-grow:1;
		margin-top:10px;
	}
}
