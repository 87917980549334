//categories
.list-group-item {
    &.active a {
        color: $white !important;
        font-weight: bold;
    }
}

//post list
.post-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    li {
        display: flex;

        @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;
        }

        border-bottom:1px solid $gray-300;
        padding:2rem 0;

        &:last-of-type {
            border-bottom: none;
        }
    }

    .featured-images {
        width: 320px;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        &:hover{
            opacity:0.8;
        }
    }

    .post-info {
        
        @include media-breakpoint-up(sm) {
            margin-left: 1.5rem;
        }
        flex: 1;
        h3{
            a:hover{
                text-decoration: none;
            }
        }
        h4 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}