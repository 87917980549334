// Core variables and mixins
// Variables

// Restated Bootstrap Variables

$white: #FFF !default;

$black: #001689 !default;
$dark: #582741 !default;
$primary: #cc2b49 !default;
$secondary: #582741 !default;
$font-size-base:1rem;
$h3-font-size:                $font-size-base * 1.6 !default;
$h4-font-size:                $font-size-base * 1.4 !default;
$h5-font-size:                $font-size-base * 1.2 !default;

@import "mixins.scss";

@import "../node_modules/bootstrap/scss/bootstrap";

@import "general";
@import "cover";
@import "faq";
@import "pricing";
@import "footer";
@import "small-header";
@import "dashboard";
@import "news";
