.dashboard-section{
	h5{
		font-weight: 600;
		font-size: 1rem;
	}
	h3{
		margin-bottom: 30px;
	}
	.field-dati{
		margin-bottom: 25px;
		span{
			font-size: 0.875rem;
			display: block;
			padding-left: 10px;
		}
	}
	.state{
		h5{
			span{
				font-weight: 400;
				font-size: 0.8em;
				&:after{
					content: "";
					display: inline-block;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					margin-left: 5px;
					
				}
			}
		}
	}
	&.pending{
		.state{
			
			h5 span{
				
				&:after{
					background: $warning;	
				}
			}
		}
	}
	&.active{
		.state{
			h5 span{
				&:after{
					background: $success;	
				}
			}
		}
	}
	&.expired{
		.state{
			h5 span{
				color: $danger;
				&:after{
					background: $danger;	
				}
			}
		}
	}
	
}