.small-header{
	padding: 15px 0;
	.progress{
		
		background: rgba($white, 0.5);
		height: 0.25rem;

		@include media-breakpoint-down(md) { 
			margin-top: 0px;
		}
	}
	.price{
		.price-total{
			font-size: 2rem;
		}
	}
}
.purchase-controls{
	width: 100%;
	max-width: 700px;
}
.purchase-breadcrumb{
	.bread-step{
		color: rgba($dark, 0.7);
		margin: bottom;
		height: 51px;
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center;
		&:before{
			content: "";
			width: 5px;
			height: 5px;
			background-color: rgba($dark, 0.7);
			display: inline-block;
			position: absolute;
			left: -3px;
			bottom: 23px;
			
		}
		&:first-child{
			&:before{
			content: none;
			
			
		}
		}
		span{
			font-size: 0.75rem;
		}
		h3{
			font-size: 1rem;
			
		}
		&.active{
			color: rgba($white, 1);
			&:before{
				background-color: rgba($white, 0.5);
			}
		}
		&.done{
			color: rgba($white, 0.5);
			&:before{
				background-color: rgba($white, 0.5);
			}
			span{
				cursor: pointer;
			}
		}
	}
}
.flux-wrapper{
	min-height: calc(100vh - 209px);
	overflow: hidden;

}
.registra-page{
	.registra-wrapper{
		position: relative;
		section{
			//display: none;
			min-height: calc(100vh - 209px);
			//display: none;
			align-items: center;
			
		}
	}
	&.step-1{
		.purchase-wrapper{
			section.pricing{
				display: flex;
				
			}
		}
	}
	&.step-2{
		.purchase-wrapper{
			section.choose-name{
				display: flex;
			}
		}
	}
	&.step-3{
		.purchase-wrapper{
			section.user-form{
				display: flex;
			}
		}
	}
	&.step-4{
		.purchase-wrapper{
			section.payment-method{
				display: flex;
			}
		}
	}
	.form-row{
		
		p{
			font-size: 18px;
			color: $dark;
		}
	}
	/* Customize the label (the container) */
	.checkbox-container {
	  display: block;
	  position: relative;
	  padding-left: 35px;
	  margin-bottom: 12px;
	  cursor: pointer;
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	}
	
	/* Hide the browser's default checkbox */
	.checkbox-container input {
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
	  height: 0;
	  width: 0;
	}
	
	/* Create a custom checkbox */
	.checkmark {
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 25px;
	  width: 25px;
	  background-color: rgba($white, 0.3);
	  transition: all .5s ease-in-out;
	  border:2px solid $primary;
	}
	
	/* On mouse-over, add a grey background color */
	.checkbox-container:hover input ~ .checkmark {
	  background-color: rgba($primary, 0.3);
	}
	
	/* When the checkbox is checked, add a blue background */
	.checkbox-container input:checked ~ .checkmark {
	  background-color: $primary;
	}
	
	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
	  content: "";
	  position: absolute;
	  display: none;
	}
	
	/* Show the checkmark when checked */
	.checkbox-container input:checked ~ .checkmark:after {
	  display: block;
	}
	
	/* Style the checkmark/indicator */
	.checkbox-container .checkmark:after {
	  left: 5px;
	  top: 0px;
	  width: 10px;
	  height: 18px;
	  border: solid white;
	  border-width: 0 3px 3px 0;
	  -webkit-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  transform: rotate(45deg);
	}
	.payment-method{
		
		.nav-tabs {
			.nav-link{
				span{
					display: block;
				}
			}
		}
	}
}