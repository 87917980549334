.accordion{
	padding: 30px 0;
	
	h2{
		
		button{
			font-size: 1.25rem;
			font-weight: 600;
			
		}
	}
	.card{
		border: none;
		.card-header{
			border: 2px solid $primary;
		border-bottom: none;
		border-top: none;
		text-align: center;
		background: #fff;
		}
		.card-body{
			border: 2px solid $primary;
			border-bottom: none;
			border-top: none;
			text-align: center;
			background: #fff;
		}
	}
}
.feature-section{
	padding: 80px 0;
}
.feature-box{
	
	height: 100%;
	.feature_image{
		margin-right: 15px;
	}
	h4{
		line-height: 1.1;
		font-size: 1.25rem;
		font-weight: 700;
	}
	p{
		line-height: 1.4;
		font-size: 0.875rem;
	}
}