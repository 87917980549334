section.pricing {
  background: $dark;
  color: $dark;
}

.pricing .card {
  border: none;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: .1rem;
  font-weight: bold;
}
.pricing .card-header, .pricing .card-footer{
	background: rgba( $dark, .2 );
	position: relative;
}
.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}
.pricing .badge{
	position: absolute;
	right: -10px;
	top: 10px;
	padding: 8px;
}
.pricing .card-price .period {
  font-size: 0.8rem;
}
.pricing ul{
	list-style: none;
	margin: 0;
	padding: 0;
}
.pricing ul li {
  padding: 0.5rem 0;
  border-top: 1px solid  rgba( $dark, .2 );
  text-align: center;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
}