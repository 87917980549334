.footer-bottom{
	background: $dark;
	
}
.payments{
	i{
		color: rgba( $white, .5 );
	}
}
.footer-social{
	
	a{
		color: rgba( $white, .7 );
		i{
			margin-right: 10px;
		}
		&:hover{
			text-decoration: none;
			color: $white;
		}
	}
}