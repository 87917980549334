.cover-section{
	min-height: calc(100vh - 130px);
	.container-fluid{
		height: 100%;
		min-height: calc(100vh - 130px);
		.row.cover-main-row{
			height: 100%;
			min-height: calc(100vh - 130px);
		}
		.tab-row{
			padding: 70px 0 0px;
		}
	}
	.claim-col{
		padding: 130px 8%;
		margin:0 auto;
		max-width: 750px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		h2{
			font-size:2.5em;
		}
		
	}
	.branding{
		margin-bottom: 70px;
		@include media-breakpoint-down(sm) { 
			margin-bottom: 30px;
		}
	}
	.tab-holder{
		max-width: 600px;
		padding: 140px 40px;
		@include media-breakpoint-down(sm) { 
			padding: 1rem 0;
		}
		background: $primary;
		width: 100%;
		.tab-pane{
			padding: 0 40px 40px;
			border: 1px solid #dee2e6;
			margin-top: -1px;
			background: $white;
			width: 100%;
			h3{
				font-size: 1.25rem;
				line-height: 1.1;
				margin: 40px 0 20px;
				
			}

		}
	}
}
.nav-tabs .nav-link{
	color: $white;
}

.divider{
	position: relative;
	text-align: center;
	margin-top: 20px;
	width: 100%;
	 a{
		 display: inline-block;
		 text-align: center;
		 color: rgba($dark, 0.5) ;
		 background: #fff;
		 padding: 0 2px;
		 font-size: 16px;
		 position: relative;
	 }
	 &:before{
		 content: "";
		 height: 2px;
		 background: rgba($dark, 0.5);
		 width: 100%;
		 position: absolute;
		 left: 0;
		 top: calc(50% - 1px);
	 }
}